// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/analytics.ts"
);
import.meta.hot.lastModified = "1735214425763.634";
}
// REMIX HMR END

import ReactGA from "react-ga4";

// Replace this with your Google Analytics Measurement ID
// const GA_TRACKING_ID = "G-B80871XPCE"; // Local
const GA_TRACKING_ID = "G-JP45FNYBLR"; // Live allinone

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const trackPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
