// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/landingPage/layouts/Layout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/landingPage/layouts/Layout.tsx");
  import.meta.hot.lastModified = "1735214425754.634";
}
// REMIX HMR END

import React from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import BackToTop from "./BackToTop";
import { Toaster } from "react-hot-toast";
export default function Layout({
  contact,
  children
}) {
  return <>
      <Header />
      <div className="main_content custom_main">{children}</div>
      <div id="toaster">
        <Toaster toastOptions={{
        duration: 5000,
        position: "top-right",
        success: {
          style: {
            background: "green",
            color: "white"
          }
        },
        error: {
          style: {
            background: "red"
          }
        }
      }} />
      </div>
      <Footer contact={contact} />
      <BackToTop />
    </>;
}
_c = Layout;
var _c;
$RefreshReg$(_c, "Layout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;